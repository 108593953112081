<i18n>
{
  "en": {
    "movie-count": "Featured movies",
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_badge": "Limited",
    "expired_badge": "Expired",
    "movie_count": "# Movies",
    "latest_movie": "Latest Movie"
  },
  "ja": {
    "movie-count": "出演作品数",
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_badge": "期間限定",
    "expired_badge": "配信終了",
    "movie_count": "出演作品数",
    "latest_movie": "最新作"
  }
}
</i18n>

<template>
  <div>
    <!-- dropdown for year select (year ranking only) -->
    <div v-if="listType == 'yearly'" class="ranking-dropdown">
      <select v-model="yearSelected" @change="updateYearlyList()">
        <option v-for="year in yearRange" :key="year" :value="year">{{ year }}</option>
      </select>
    </div>

    <div class="section is-dense">
      <!-- weekly/monthly/yearly list -->
      <div v-if="listType !== 'actress'" class="grid is-ranking">
        <div class="grid-item" v-for="(movie, index) in rankingList" :key="movie.MovieID">
          <div class="entry">
            <div class="entry-ranking">
              <div v-if="index == 0" class="ranking-number is-gold">{{ index + 1 }}</div>
              <div v-if="index == 1" class="ranking-number is-silver">{{ index + 1 }}</div>
              <div v-if="index == 2" class="ranking-number is-bronze">{{ index + 1 }}</div>
              <div v-if="index > 2" class="ranking-number">{{ index + 1 }}</div>
            </div>
            <div class="entry-media">
              <div v-if="!newXPEligible && movie.isVIP" class="tag-member is-vip">{{ $t('vip_badge') }}</div>
              <div v-if="!newXPEligible && movie.isSVIP" class="tag-member is-svip">{{ $t('svip_badge') }}</div>
              <div v-if="!newXPEligible && movie.isAnnual" class="tag-member is-annual">{{ $t('annual_badge') }}</div>
              <router-link :to="`/movies/${movie.MovieID}/`" class="media-thum">
                <img
                  :src="`${movie.thumbnailSrc}`"
                  @error="useFallbackImage(index)"
                  class="media-image"
                  loading="lazy"
                  v-sfw
                />
              </router-link>
            </div>
            <div class="entry-meta has-button">
              <router-link :to="`/movies/${movie.MovieID}/`">
                <div v-if="movie.isLimited" class="tag-status is-limited">{{ $t('limited_badge') }}</div>
                <div v-if="movie.isExpired" class="tag-status is-expired">{{ $t('expired_badge') }}</div>
                <div class="meta-data">{{ movie.Release }}</div>
                <div v-if="locale == 'ja'">
                  <div class="meta-title">{{ movie.Title }}</div>
                  <div class="meta-data">{{ movie.Actor }}</div>
                </div>
                <div v-if="locale == 'en'">
                  <div
                    v-if="movie.TitleEn != null && movie.TitleEn != ''"
                    class="meta-title"
                  >{{ movie.TitleEn }}</div>
                  <div
                    v-else
                    class="meta-title"
                    v-for="(actress, index) in movie.ActressesEn"
                    :key="index"
                  >{{ actress }}</div>
                </div>
              </router-link>
              <div class="meta-button" @click="showModalSample(movie.MovieID)">
                <button class="button is-filled is-xsmall"><svg class="dl-icon is-left"><use xlink:href="#dl-icon-play-fill" /></svg>サンプル再生</button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.list -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* eslint no-shadow: 0 */

import range from 'lodash/range';
import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';

export default {
  props: {
    listSize: {
      type: Number,
      default: 10,
    },
    listType: {
      type: String,
      default: 'weekly',
    },
    listName: {
      type: String,
    },
  },
  data() {
    return {
      rankingList: [],
      yearSelected: new Date().getFullYear(),
      posterFallbackTries: {},
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  created() {
    // non-reactive consts
    this.currentYear = new Date().getFullYear();
    this.yearRange = range(2010, new Date().getFullYear() + 1).reverse();

    // set listener for emit event from the parent tab component
    EventBus.$on('ranking:tab:active', async (title) => {
      // get list if the title matches the 1st character and hasn't been fetched yet
      if (this.listName === title && this.rankingList.length === 0) {
        this.getRankings(this.listType, this.userTypeID);
      }
    });
  },
  methods: {
    getRankings(listType, userTypeID) {
      const bfAPI = new BifrostAPI();

      switch (listType) {
        case 'weekly':
          bfAPI.getMovieListWeekly(0, this.listSize).then((result) => {
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
        case 'monthly':
          bfAPI.getMovieListMonthly(0, this.listSize).then((result) => {
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
        case 'yearly':
          bfAPI
            .getMovieListYearly(
              0,
              this.listSize,
              parseInt(this.yearSelected, 10),
            )
            .then((result) => {
              const rankingList = MovieHelper.expandDetailsList(
                result.Rows,
                this.locale,
                userTypeID,
              );
              this.rankingList = MovieHelper.filterExcludedMovieList(
                rankingList,
                userTypeID,
              );
            });
          break;
        case 'actress':
          // based on actresses from the monthly movie ranking
          bfAPI.getMovieListMonthly(0, this.listSize).then((result) => {
            const movieList = result;
            for (let i = 0; i < movieList.Rows.length; i += 1) {
              movieList.Rows[i].MovieCount = 0;

              // get actress movies count
              // capturing i in a closure since we're passing it to a promise (otherwise i will
              // always be the same value without the closure)
              ((i) => {
                bfAPI
                  .getMovieListActress(0, 500, movieList.Rows[i].ActorID[0])
                  .then((actressMovies) => {
                    movieList.Rows[i].MovieCount = actressMovies.TotalRows;
                  });
              })(i);
            }
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
        default:
          bfAPI.getMovieListWeekly(0, this.listSize).then((result) => {
            const rankingList = MovieHelper.expandDetailsList(
              result.Rows,
              this.locale,
              userTypeID,
            );
            this.rankingList = MovieHelper.filterExcludedMovieList(
              rankingList,
              userTypeID,
            );
          });
          break;
      }
    },
    updateYearlyList() {
      const bfAPI = new BifrostAPI();
      bfAPI
        .getMovieListYearly(0, this.listSize, parseInt(this.yearSelected, 10))
        .then((result) => {
          const rankingList = MovieHelper.expandDetailsList(
            result.Rows,
            this.locale,
            this.userTypeID,
          );
          this.rankingList = MovieHelper.filterExcludedMovieList(
            rankingList,
            this.userTypeID,
          );
        });
    },
    imgPlaceholder(e) {
      e.target.src = '/img/common/actress_placeholder.png';
    },
    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.rankingList[index].MovieID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(
          this.rankingList[index],
          'thumbnailSrc',
          `/assets/sample/${this.rankingList[index].MovieID}/str.jpg`,
        );
        this.posterFallbackTries[this.rankingList[index].MovieID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(
          this.rankingList[index],
          'thumbnailSrc',
          '/img/common/now_printing_square.png',
        );
      }
    },
    showModalSample(movieId) {
      this.$modal.show('ui-modal-video', { movieId });
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getRankings(this.listType, newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
